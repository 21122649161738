import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const w05LK07Fischerschein = () => (
  <Layout>
    <SEO title="w05LK07Fischerschein" />

    <h1 id="ueberschrift">Lernkreis</h1>
    <h2>staatliche Fischerprüfung Bayern &emsp;  ©&nbsp;2019</h2>

      <p>
        Kontakt:&ensp;
        <a href="mailto:iarna@bastet-project.de?subject=Anfrage zu w05LK07Fischerschein&amp;body=Bitte senden Sie so viele Daten wie möglich (z.B.: gewünschter Ort, Tag, Anzahl der Teilnehmer, Altersgruppe, etc.)." title="per E-Mail kontaktieren" style={{ fontSize: "1em" }}>iarna@bastet-project.de</a>
      </p>

      <p>
        Meist ist es einfacher etwas zu lernen, wenn es mit anderen gemeinsam geübt werden kann.
        Aus diesem Grund bietet dieser Kurs die Möglichkeit
        bei einem gemütlichen Beisammensein das Gewünschte zu trainieren. Durch die Mischung meiner
        Kenntnisse mit Ihren Situationen und Interessen können wir den Kursinhalt weitmöglichst Ihren Bedürfnissen anpassen.
      </p>
      <p style={{fontStyle: "italic"}}>
        <b style={{textDecoration: "underline"}}>Experten, die Ihr Fachwissen teilen wollen, sind herzlich willkommen!</b><br />
        Bitte erkundigen Sie sich vorab, wie Sie Ihre Fähigkeiten einbringen können.
      </p>
      
      <p style={{fontStyle: "italic", textDecoration: "underline"}}>
        Wissensaustausch &amp; Ideenfindung
      </p>
      <p>
        für alle Altersgruppen<br/>
        Platzbedarf: 1 Sitzplatz/Tisch pro Teilnehmer + 1 Sitzplatz/Tisch extra<br/>
        Materialbedarf: + Schulungsunterlagen der Fischerschule Nürnberger Land +
      </p>
      <p>
        Privatstunden möglich<br/>
        Platzbedarf: 2 Sitzplätze/Tische
      </p>
      <p>
        Voraussetzungen: + ordnungsgemäße Anmeldung bei der Fischerschule Nürnberger Land +<br />
        siehe: www.fischerschule-nuernberger-land.de
      </p>

    <Link to="/fdml_1a_aktuelles-klartext/" title="Detaillierte Informationen zum Bereich Veranstaltungen &amp; mehr" >Aktuelle Termine</Link>
      <p></p>
  </Layout>
              )

              export default w05LK07Fischerschein
